<template>
  <div class="content bg8">
    <div class="middled">
      <div class="basehero">
        <h1>About Us</h1>
        <h2 class="section">
          We help startups build and scale with focus and clarity.
        </h2>
        <p>
          Our mission is to empower startup founders with the focus and clarity they need to excel. 
          We instill confidence in leaders, equipping them to navigate critical business challenges effectively. 
          The Foclarity platform provides a personalized AI assistant and essential tools to ensure teams are focused and aligned on key objectives, fostering sustained growth, innovation, and operational excellence.
        </p>
        <p>
          Foclarity AI is available directly to <a href="/who-we-serve">startups</a> or as a platform for <a href="/incubators">startup incubators</a> to support their members.
        </p>
        <h2 class="section">The Foclarity Way</h2>
        <p>
          The proprietary AI-powered platform is grounded in fundamentals that improve strategic thinking, planning, and alignment, using data, generative AI, and a pattern of learning, application, implementation, and reporting.
        </p>
        <p>Foclarity helps you:</p>
        <ul class="bullets bene">
          <li><strong>Boost Team Performance:</strong> Elevate your team's productivity to consistently achieve and surpass goals.</li>
          <li><strong>Drive Growth and Development:</strong> Foster not just business growth, but also the professional advancement of your team.</li>
          <li><strong>Enhance Communication and Collaboration:</strong> Streamline team interaction for efficiency and effectiveness.</li>
          <li><strong>Cultivate Innovation:</strong> Empower your team to think creatively and solve problems with cutting-edge solutions.</li>
          <li><strong>Build Adaptability:</strong> Equip your team to thrive amidst changes and bounce back stronger from challenges.</li>
          <li><strong>Improve Employee Satisfaction:</strong> Create a work environment where team members feel engaged and valued.</li>
          <li><strong>Align with Vision:</strong> Ensure every team effort contributes directly to your company's overarching goals.</li>
          <li><strong>Uphold Cultural and Ethical Integrity:</strong> Maintain a positive, inclusive, and ethical workplace.</li>
          <li><strong>Maximize Operational Efficiency:</strong> Optimize processes to achieve more with less, reducing costs and increasing value.</li>
        </ul>
        <div style="background:#f0f8ff; border:1px solid #ccc;padding:40px;margin: 50px 0 ;">
          <h2 style="text-align: center;">Need help getting started?</h2>
          <p style="text-align: center;">
            Schedule a call and learn how it works.
          </p>
          <p style="text-align: center;">
            <a href="/contact">
                <button class="wide" style="background: #000;"> Schedule a Demo </button>
            </a>
          </p>
        </div>

        <div>
          <h1>Our Team</h1>
          <section>
            <h2 class="section">
              Michael Ashley, CEO
            </h2>
            <img src="@/assets/mash-michael-ashley.png" alt="Mash Michael Ashley, MBA" style="float:left;margin:0px 15px 0px 0;border-radius:50%;width:140px;height:140px;border:1px solid #ccc;">
            <p>
              Michael Ashley, AKA Mash, is the CEO and Founder of Foclarity. With over 25 years experience developing enterprise software solutions and coaching startup founders,
              Mash has developed a coaching platform and software designed to help startup founders strategize,
              launch, and scale their ventures faster and more effectively.
            </p>
            <p>
              He is also the Startup Incubator Launch Director at San José State University and Adjunct Professor in the College of Business, 
              where his expertise spans Operations and Program Management, International Business, Entrepreneurship, and Strategy. 
              As the Launch Director for the SpartUp Incubator at SJSU, Mash has played a pivotal role in incubating over 30 startup teams, 
              significantly impacting the entrepreneurial landscape. 
              His real-world experience as an entrepreneur and advisor, marked by four successful exits, 
              lends practical insights into his teaching and mentorship. 
            </p>
            <p> 
              His work in the field of business strategy, coupled with a rich educational background in business and technology, 
              position him as a thought leader in strategy and entrepreneurship. 
              Mash's approach to business is characterized by a blend of academic rigor and real-world pragmatism, 
              making him a sought-after speaker and expert in the startup community. (<a href="https://www.linkedin.com/in/mashley/" target="_blank">LinkedIn</a>)
            </p>
          </section>

          <section>
            <p>
              We are hiring. Connect with us on <a href="https://www.linkedin.com/company/foclarity/" target="_blank">LinkedIn</a> to learn more.
            </p>
          </section>
        </div>

        <div v-show="false">
          <section>
            <h2>Our Story</h2>
            <p>
              We are a team of management consultants who share a vision to revolutionize how companies adapt to change and achieve sustainable growth.
              We are dedicated to helping business leaders accelerate through top-level challenges with enormous impact. 
              Foclarity is our system of frameworks and software, proven to drive growth, retention and customer success with focus, clarity and alignment.
            </p>
          </section>

          <section>
            <h2>Our Expertise</h2>
            <p>
              At Foclarity, we take pride in over 40 years combined experience guiding enterprise companies and startups through major challenges, 
              accelerating growth and success. 
              Our team of seasoned professionals brings a wealth of knowledge and a proven track record of successful transformations in diverse industries.
            </p>
          </section>

          <section>
            <h2>Customer Focus</h2>
            <p>
              Our clients are at the heart of everything we do. 
              At Foclarity, we strive to understand our customers' unique needs and tailor our solutions to address their specific business transformation requirements. 
              Customer success and satisfaction drive us to continuously innovate and deliver exceptional results.
            </p>
          </section>
          
          <section>
            <h2>Company Culture</h2>
            <p>
              At Foclarity, we foster a culture of collaboration, innovation, and continuous learning. 
              Our diverse team of experts shares a passion for creating cutting-edge software solutions that empower leaders to lead successful transformations. 
              We believe that our people are the driving force behind our achievements.
            </p>
          </section>
          
          <section>
            <h2>Social Responsibility</h2>
            <p>
              Giving back to the community is an integral part of our corporate ethos. 
              At Foclarity, we actively engage in various philanthropic initiatives and support causes that drive positive change. 
              Our commitment to social responsibility extends beyond our software, making a meaningful impact on society.
            </p>
          </section>
          
        </div>

      </div>
    </div>    
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";


export default {
  title: 'About Us | Foclarity',
  name: "WhoWeAre",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    }
  },
  components: {},
  data () {
      return {
      }
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h3 {
      font-size: 23px;
      line-height: 32px;
      color: #CC0099;
      text-align: left;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}
</style>

