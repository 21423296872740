import { authHeader, router } from '../_helpers';

export const chatService = {
    chat,
};

function chat(user_message, chat_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ user_message, chat_id })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/chats"

        // console.log("endpoint", endpoint)
        // console.log("chat_message", user_message)
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                //location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

