<template>
    <div class="content bg8">
      <div class="middled">
        <div class="basehero">
          <h1>
            Incubate Startups at Scale
          </h1>
          <h2 class="section">
            Foclarity for Incubators helps you deliver AI-powered learning and planning frameworks to your founders—at scale.
          </h2>
          <p>
            Most incubators and accelerators are limited by the number of founders they can support in a cohort.
            Foclarity helps you scale your impact by providing founders with a self-service AI-powered platform to learn, plan, and track progress.
          </p>
          <p>
            Founders need to create actionable plans with assumption testing to build and scale a business.
            As the Director of an Incubator you are already hustling to create and manage great programs for your founders.
            Now you can use Foclarity to support your goals, and give founders a place to learn, plan, and track progress.
          </p>
          <p>
            You get custom AI-powered learning software and proprietary frameworks to support startups in your programs.
            Use our default frameworks, or design your own library founders can clone quickly and easily.
            Train AI to help you deliver more value to your startups.
            Create a solid foundation and make an enormous impact with your founders.
          </p>
          <p>
            You also get access to cutting edge tools to manage your own programs, events, workshops and more.
            We provide you with support as you work with founders, a library of frameworks they can use,
            and a community of other Incubators to collaborate with.
          </p>

          <h2 class="section">
            Founder Assessment and Learning Paths:
          </h2>
          <p>
            Foclarity assessments help founders understand where they are in the startup lifecycle.
            Initial learning paths are designed to help founders through the launch phase of ideation, prototyping, planning and fundrasing,
            and into the growth phase of scaling, hiring, and managing a team. It starts with:
          </p>

          <div class="row middled" style="margin-bottom:60px;">
            <div class="column">
              <div class="homecard">
                <div class="cardicon">
                  <img :src="require('@/assets/ic_assets.png')" alt="Capture ideas" />
                </div>
                <div class="h3">
                  IDEATION
                </div>
                <p class="gray">Learn how to develop ideas, do market research, and find first customers</p>
              </div>
            </div>
            <div class="column">
              <div class="homecard">
                <div class="cardicon">
                  <img :src="require('@/assets/ic_goals.png')" alt="Score efforts" />
                </div>
                <div class="h3">
                  PROTOTYPING
                </div>
                <p class="gray">Build a minimum viable product (MVP), conduct user testing and get feedback</p>
              </div>
            </div>
            <div class="column">
              <div class="homecard">
                <div class="cardicon">
                  <img :src="require('@/assets/ic_measure.png')" alt="Create tasks" />
                </div>
                <div class="h3">
                  PLANNING
                </div>
                <p class="gray">Develop a business model, go-to-market strategy, and financial plan</p>
              </div>
            </div>
            <div class="column">
              <div class="homecard">
                <div class="cardicon">
                  <img :src="require('@/assets/ic_polls.png')" alt="Visualize progress" />
                </div>
                <div class="h3">
                  FUNDRAISING
                </div>
                <p class="gray">Learn how to pitch, find the right investors, and securing funding</p>
              </div>
            </div>
          </div>

          <p>
            If you are running an accelerator or incubator, Foclarity will save you time, deliver greater value, 
            and help you generate more economic impact per client.
          </p>

          <div style="background:#f0f8ff; border:1px solid #ccc;padding:40px;margin: 50px 0 ;">
            <h2 style="text-align: center;">Foclarity for Incubators</h2>
            <p style="text-align: center;">
              Schedule a call and learn how it works.
            </p>
            <p style="text-align: center;">
              <a href="/contact">
                  <button class="wide" style="background: #000;"> Schedule a Call </button>
              </a>
            </p>
          </div>          
        </div>
      </div>    
    </div>
  </template>
  
  <script>
  import { isLoggedIn } from "../utils/auth";
  
  export default {
    title: 'AI Platform for Incubators | Foclarity',
    name: "Coaches",
    mounted: function() {
      if (isLoggedIn()) {
        // this.$router.push("dashboards");
      }
    },
    methods: {
      isLoggedIn() {
        return isLoggedIn();
      }
    },
    components: {},
    data () {
        return {
        }
    },
    created () {
    }
  };
  </script>
  
  <style lang="scss" scoped>
  
  .heroblue {
    background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
    height: 320px;
    margin: -20px -20px 20px -20px;
    border-radius: 4px 4px 0 0;
    @media only screen and (max-width: 440px) {
      border-radius: 0px 0px 0 0;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    & h1 {
      text-align: center;
      color: #fff;
      font-size: 45px;
      max-width: 600px;
      @media (max-width: 415px) {
        font-size: 36px;
        padding: 20px;
      }
    }
    & a {
      text-decoration: none;
      color: #fff;
      & .signupbox {
        text-align: center;
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
        background: #ff7f24;
        width: 300px;
        border-radius: 10px;
        border: 1px solid #fff;
        margin: auto;
      }
    }
  }
  
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding: 10px;
    min-width: 200px;
    & .homecard {
      text-align: center;
      padding: 20px;
      border-radius: 4px;
      background: rgba(0, 178, 238, 0.1);
      height: 100%;
      & .cardicon {
        width: 100px;
        height: 100px;
        margin: auto;
        & img {
          width: 80px;
        }
      }
      & .h34 {
        font-size: 23px;
        line-height: 32px;
        color: #CC0099;
        text-align: left;
        @media (max-width: 400px) {
          font-size: 20px;
        }
      }
    }
  }

  .homequote {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-style: italic;
    color: #666;
    max-width: 600px;
    margin: 20px auto;
  }
  </style>
  
  