<template>
  <div class="content">
    <div class="middled">
      <div v-if="account && account.logo_url" style="margin:20px;">
        <div style="width:300px;margin:auto;height:100px;background: url('https://www.foclarity.com/icons/apple-icon.png');background-size: contain !important;" :style="{backgroundSize : 'contain !important', backgroundPosition : 'center', 'background' : 'url(' + 'https://corl8-files.s3.amazonaws.com/' + account.logo_url + ')', backgroundRepeat : 'no-repeat'}"></div>
      </div>
      <h1 style="text-align: center;">
        {{ account.account_name }}
      </h1>
    </div>

    <div class="middled" v-show="!loading">
      <transition name="fade" >
        <div v-if="error" class="alert alert-danger">{{error}}</div>
      </transition>
      <div v-if="mission.mission_id" v-show="!toggleEdit">
        <div v-if="mission.account_vision">
          <h4>Vision</h4>
          <p class="missiontxt">{{mission.account_vision}}</p>
        </div>
        <div v-if="mission.account_mission">
          <h4>Mission</h4>
          <p class="missiontxt">{{mission.account_mission}}</p>
        </div>
        <div v-if="mission.account_strategy">
          <h4>Strategy</h4>
          <p class="missiontxt">{{mission.account_strategy}}</p>
        </div>
        <div v-if="mission.account_values">
          <h4>Values</h4>
          <p class="missiontxt">{{mission.account_values}}</p>
        </div>
        <div v-if="mission.account_priorities">
          <h4>Corporate Level Priorities</h4>
          <p class="missiontxt">{{mission.account_priorities}}</p>
        </div>
        <div v-if="mission.account_summary">
          <h4>Summary</h4>
          <p class="missiontxt">{{mission.account_summary}}</p>
        </div>
        <div v-if="account.user_id === user.user_id" v-show="!toggleEdit" v-on:click="openEditor"><button style="min-width: 100px;">Edit</button></div>
      </div>


      <div v-else style="padding:0px;">
        <div v-if="account.user_id === user.user_id" >
          <div class="mission" v-show="!toggleEdit">
            <p>
              Focus, clarity, and alignment start with your vision, mission, and strategy.
            </p>
            <p>
              <a href="#" v-on:click.prevent="toggleEdit = true">Click here to get started...</a>
            </p>
          </div>
        </div>
        <div v-else>
          <div class="mission">
            <h4>Vision, Mission, and Strategy</h4>
            <p>
              The Account Owner has not yet entered the Vision, Mission, and Strategy.
            </p>
          </div>
        </div>
      </div>
    </div>
    
    <div class="middled" v-show="toggleEdit">
      <div v-if="!loading">
        <div class="mission-form">
          <form @submit.prevent="handleSubmit">
            <div class="form-grp">
              <label for="account_vision">Vision</label>
              <textarea v-model="mission.account_vision" name="account_vision" />
            </div>
            <div class="form-grp">
              <label for="account_mission">Mission</label>
              <textarea v-model="mission.account_mission" name="account_mission" />
            </div>
            <div class="form-grp">
              <label for="account_strategy">Strategy</label>
              <textarea v-model="mission.account_strategy" name="account_strategy" />
            </div>
            <div class="form-grp">
              <label for="account_values">Values</label>
              <textarea v-model="mission.account_values" name="account_values" />
            </div>
            <div class="form-grp">
              <label for="account_priorities">Corporate Level Priorities</label>
              <textarea v-model="mission.account_priorities" name="account_priorities" />
            </div>
            <div class="form-grp">                
              <label v-if=" mission.account_summary && mission.account_summary.length == 0" ><a v-on:click.prevent="generateSummary" :disabled="generating.length > 0">Generate a summary using AI (beta)</a></label>
              <label v-else ><a v-on:click.prevent="generateSummary" :disabled="generating.length > 0">Generate summary (beta)</a></label>
              <textarea v-model="mission.account_summary" name="account_summary" placeholder="Click the link above summarize your input using ChatGPT" />
              <p v-if="generating.length == 0" class="tiny">(Beta / AI GPT 3.5-Turbo)</p>
              <p v-else class="tiny">{{ generating }}</p>
            </div>
            <div class="form-group">
              <button class="btn btn-primary" :disabled="loading">Save</button> | <a href="#" v-on:click="toggleEdit = false">Cancel</a>
              <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { accountService, userService } from '../_services';
import { router } from "../_helpers";

export default {
  title: 'Vision, Mission and Values | Foclarity',
  name: "Mission",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    openEditor: function () {
      if(this.account.account_id && (this.account.user_id === this.user.user_id)){
        this.toggleEdit = true
      }
    },
    generateSummary: function () {
      if(confirm("Click OK to Generate a summary using AI.")){
        this.generating = "Please wait... generating takes 20 seconds"
        const { mission } = this;
        accountService.generateMissionSummary(mission.account_vision, mission.account_mission, mission.account_strategy, mission.account_values, mission.account_priorities)
        .then( data => {
            if (data.status === 'success') {
              if (data.summary === undefined) {
                this.tips = "Unfortunately, we can't process that much data with AI yet."
              } else {
                this.mission.account_summary = data.summary
              }
              this.generating = "" ;
            } else {
              this.generating = data.message
            }
          },
          error => {
            this.generating = error
          }
        )
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { mission } = this;

        this.loading = true;
        accountService.createMission(mission.account_vision, mission.account_mission, mission.account_strategy, mission.account_values, mission.account_priorities, mission.account_summary)
            .then(
                // eslint-disable-next-line no-unused-vars
                user => {
                    this.error = 'Mission created and saved.';
                    this.loading = false
                    this.toggleEdit = false
                    setTimeout(() => this.error = "", 3000);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                }
            );
    }    
  },
  components: {},
  data () {
      return {
        user: JSON.parse(localStorage.getItem('user')),
        account: {},
        mission: {},
        account_vision: '',
        account_mission: '',
        account_strategy: '',
        account_values: '',
        account_priorities: '',
        account_summary: '',
        submitted: false,
        toggleEdit: false,
        loading: true,
        returnUrl: 'dashboards',
        error: '',
        generating: ""
      }
  },
  created () {
    userService.getMe().then(data => {
      this.user = data
      this.account = accountService.getActiveAccount()
      if (this.account == null) {
        router.push("/accounts")
        return
      }
      if (this.account.account_name) {
        window.document.title = this.account.account_name + " mission, vision and values workspace | Foclarity"
      }
    });
    accountService.getMission().then(data => {
      if (data) {
        this.mission = data
        this.account_vision = data.account_vision
        this.account_mission = data.account_mission
        this.account_strategy = data.account_strategy
        this.account_values = data.account_values
        this.account_priorities = data.account_priorities
        this.account_summary = data.account_summary
        this.loading = false;
      } else {
        this.loading = false ;
      }
    },
    error => {
      this.error = error;
      this.loading = false;
    })
  }
};
</script>

<style lang="scss" scoped>
.mission-form {
  max-width: 800px;
  padding: 20px;
  & input {
    background: #eee ;
  }
}
h4 {
 font-size: 1.2rem;
}
.missiontxt {
  white-space: break-spaces;
  color:#333;
}
</style>

