<template>
  <div id="top" class="content">
    <div class="middled">

      <div class="row searchrow">
        <div class="column">
          <div class="breadcrumbs">
            <router-link :to="{ name: 'dashboards'}" class="small-hider">{{ account.account_name }}</router-link>
            > <router-link :to="{ name: 'scorecard', params: { scorecard_id: $route.params.scorecard_id }}" style="text-transform:capitalize;">{{ scorecard.scorecard_name }}</router-link>
            >
            <select @change='changeCategory($event)' v-model='category.category_id'>
                <option
                v-for='(category, index) in categories'
                :value='category.category_id'
                :key='index'>
                {{ category.category_name | truncate(30, '...') }}
                </option>
            </select>
          </div>
        </div>
        <div class="column">
          <searchbar />
        </div>
      </div>

      <div v-show="loading" style="text-align:center;">Loading...</div>
      <div v-show="!loading">
          <div>

            <div class="row">
              <div class="column">
                <h1 style="text-transform:capitalize;">
                  {{ category.category_name }}
                </h1>
                <div style="margin:10px 0 ;white-space: break-spaces;" v-html="category.category_description" v-linkified />
                <p v-if="category_owners.length > 0" class="tiny">
                  Owners: 
                  <span v-for="(owner, index) in category_owners" :key="index">
                    <router-link :to="{ name: 'measure', params: { scorecard_id: $route.params.scorecard_id, category_id: $route.params.category_id, measure_id: owner.measure_id }}">
                      {{ owner.owner_first_name }}<template v-if="index < (category_owners.length - 1)">, </template>
                    </router-link>
                  </span>
                </p>
                <div class="smnav">
                  <img v-if="(account.account_id === scorecard.account_id)" :src="require('@/assets/ic_edit.png')" @click="showEdit = true" width="28" height="28" alt="Edit Category" title="Edit Category" style="cursor:pointer;" />
                  <router-link v-if="(account.account_id === scorecard.account_id)" :to="{name: 'new-measure', params: { scorecard_id: scid, category_id: cid }}">
                    <img :src="require('@/assets/ic_add.png')" width="28" height="28" alt="Add Measure" title="Add Measure" style="cursor:pointer;" />
                    <span v-if="measures.length < 2"> &larr; Add tiles</span>
                  </router-link>
                  <div v-if="measures.length > 2" style="font-weight:bold;margin:0 10px;">{{ measures.length }} Tiles:</div>
                  <div class="quick-buttons">
                    <div v-show="false">
                      <a class="mobutton" href="#" v-show="prev" v-on:click.prevent="prevCategory">Previous Category</a>
                      <a class="mobutton" href="#" v-show="next" v-on:click.prevent="nextCategory">Next Category</a>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="categories">
              <div class="row catrow">
                <div class="column mebox" v-for="measure in measures" :key="measure.measure_id">
                  <div class="cat rank0" :class="'rank' + measure.snapshot.rank_score" >
                    <router-link :to="{name: 'measure', params: {scorecard_id: $route.params.scorecard_id, category_id: $route.params.category_id, measure_id: measure.measure_id}}">
                      <div class="row" :class="'rank' + measure.snapshot.rank_score">
                        <div class="ranknum">{{ measure.snapshot.rank_score ? measure.snapshot.rank_score : '0' }}</div>
                        <div v-show="true" class="rankname">{{ measure.snapshot.benchmark_description ? measure.snapshot.benchmark_description : 'No Status' }}</div>
                        <div v-if="measure.measure_owners" style="margin-left:auto;padding-right: 8px;">
                          <span style="color:#fff;" class="tiny" v-for="(owner, index) in measure.measure_owners" :key="index">
                            <strong>{{ owner.owner_first_name}}</strong>
                          </span>
                        </div>
                      </div>
                      <div style="background:#fff;margin:0 4px 4px 4px;text-align:center;height: 160px;overflow: hidden;"  :title="measure.snapshot.benchmark_description">
                        <div style="min-width:200px; margin: 0;position: absolute;top: 45%;left: 50%;transform: translate(-50%, -50%);"> 
                          <strong>{{ measure.measure_name | truncate(50, '...') }}</strong>
                          <div v-if="measure.snapshot.benchmark_description" class="tiny" style="color:#333;">
                            <div v-if="measure.snapshot.snapshot_notes && measure.snapshot.snapshot_notes.startsWith(' --')">
                              {{ measure.snapshot.benchmark_description }}
                              {{ measure.snapshot.snapshot_notes }}
                            </div>
                            <div v-else>
                              <span v-if="measure.snapshot.snapshot_notes">
                                {{ measure.snapshot.snapshot_notes | truncate(100, '...') }}
                              </span>
                              <span v-else>
                                {{ measure.snapshot.benchmark_description }}
                              </span>
                            </div>
                          </div>
                          <div v-else class="tiny" style="color:#333;">
                            <span v-if="measure.measure_description.includes('http')"></span>
                            <span v-else>
                              {{ measure.measure_description | truncate(100, '...') }}
                            </span>
                          </div>
                        </div>
                        <div class="tiny" style="text-align:center;width:100%;position: absolute;bottom: 2px;left: 0;" v-if="measure.snapshot.benchmark_description">
                          as of {{ measure.snapshot.created_at | moment("MMM DD, YYYY") }}<span v-if="measure.snapshot.user_name">{{ " - " + measure.snapshot.user_name  }}</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>

                <div v-if="(measures.length == 0) && (scorecard.scorecard_id > 0)" style="width:100%;padding:20px;margin:20px auto;text-align:center;">
                    <h2 style="text-align:center;">
                      <span :class="{highlightyellow: measures.length < 2}">
                        Add three to five tiles inside <br>&ldquo;{{ category.category_name }}&rdquo;
                      </span>
                    </h2>
                  <p> 
                    A <em>Tile</em> is a workspace inside &ldquo;{{ category.category_name }}&rdquo;, like a topic, where you can brainstorm, capture learnings, notes, ideas, and progress.
                  </p>
                  <p style="text-align:center;">
                    <router-link v-if="(account.account_id === scorecard.account_id)" :to="{name: 'new-measure', params: { scorecard_id: scid, category_id: cid }}">
                      <button style="padding:10px 20px;">Add a Tile</button>
                    </router-link>
                  </p>
                </div>

                <div v-show="false" class="column" v-for="measure in measure_samples" :key="measure.measure_id" style="min-width: 220px;">
                  <div class="cat rank0" :class="'rank' + measure.snapshot.rank_score" v-if="(account.account_id === scorecard.account_id)">
                    <router-link :to="{name: 'new-measure', params: { scorecard_id: scid, category_id: cid }}">
                      <div class="row" :class="'rank' + measure.snapshot.rank_score">
                        <div class="ranknum">{{ measure.snapshot.rank_score ? measure.snapshot.rank_score : '0' }}</div>
                        <div class="rankname">{{ measure.snapshot.benchmark_description ? measure.snapshot.benchmark_description : 'No Status' }}</div>
                        <div v-if="measure.measure_owners" style="margin-left:auto;padding-right: 8px;">
                          <span style="color:#fff;" class="tiny" v-for="(owner, index) in measure.measure_owners" :key="index">
                            <strong>{{ owner.owner_first_name}}</strong>
                          </span>
                        </div>
                      </div>
                      <div style="background:#fff;margin:0 4px 4px 4px;text-align:center;height: 160px;overflow: hidden;"  :title="measure.snapshot.benchmark_description">
                        <div style="min-width:200px; margin: 0;position: absolute;top: 45%;left: 50%;transform: translate(-50%, -50%);"> 
                          <strong>{{ measure.measure_name | truncate(50, '...') }}</strong>
                          <div v-if="measure.snapshot.benchmark_description" class="tiny" style="color:#333;">
                            <div v-if="measure.snapshot.snapshot_notes && measure.snapshot.snapshot_notes.startsWith(' --')">
                              {{ measure.snapshot.benchmark_description }}
                              {{ measure.snapshot.snapshot_notes }}
                            </div>
                            <div v-else>
                              <span v-if="measure.snapshot.snapshot_notes">
                                {{ measure.snapshot.snapshot_notes | truncate(100, '...') }}
                              </span>
                              <span v-else>
                                {{ measure.snapshot.benchmark_description }}
                              </span>
                            </div>
                          </div>
                          <div v-else class="tiny" style="color:#333;">
                            <span v-if="measure.measure_description.includes('http')"></span>
                            <span v-else>
                              {{ measure.measure_description | truncate(100, '...') }}
                            </span>
                          </div>
                        </div>
                        <div class="tiny" style="text-align:center;width:100%;position: absolute;bottom: 2px;left: 0;" v-if="measure.snapshot.benchmark_description">
                          as of {{ Date.now() | moment("MMM DD, YYYY") }}<span v-if="measure.snapshot.user_name">{{ " - " + measure.snapshot.user_name  }}</span>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>

              </div>
            </div>

            <div id="tasks" class="smnav" v-if="measures.length > 0">
              <h2>Action Items:</h2>
              <div class="quick-buttons">
                <a href="#" @click.prevent="showAddTask = true" v-if="categories.length > 0" style="text-decoration: none;">
                  <button style="padding: 6px 12px;">New Task</button>
                </a>
              </div>
            </div>

            <div style="margin-top:20px;" v-if="snapshots.length > 0">
              <div>
                <AccountTasks v-bind:scid="scid" v-bind:user_id="user.user_id" v-bind:category_id="category.category_id" v-bind:category_name="category.category_name" :key="taskrender" />
              </div>
            </div>

            <div id="status" style="margin-top:20px;" v-if="(category_score > 0) && (measures.length > 1)">
              <div id="chart" v-if="measures.length > 2">
                <apexchart type="radar" height="400" :options="chartOptions" :series="series" :key="catkey"></apexchart>
              </div>              
            </div>

            <div v-if="snapshots.length > 0">

        <div class="section-bar" v-if="snapshots.length > 0">
          <h2>Activity:</h2>
        </div>

        <div class="tiny">
          <a href="#" @click.prevent="notescore = 6" :class="{activefilter : notescore == 6 }">None</a>
          |
          <a href="#" @click.prevent="notescore = 0" :class="{activefilter : notescore == 0 }">Recent</a>
          |
          <a href="#" @click.prevent="notescore = 1" :class="{activefilter : notescore == 1 }">On Hold</a>
          |
          <a href="#" @click.prevent="notescore = 2" :class="{activefilter : notescore == 2 }">Behind</a>
          |
          <a href="#" @click.prevent="notescore = 3" :class="{activefilter : notescore == 3 }">On Track</a>
          |
          <a href="#" @click.prevent="notescore = 4" :class="{activefilter : notescore == 4 }">Almost Done</a>
          |
          <a href="#" @click.prevent="notescore = 5" :class="{activefilter : notescore == 5 }">Completed</a>
        </div>

              <div v-if="snapshots.length > 0" style="margin-top:10px;">

                <div v-for="note in filteredSnapshots" :key="note.id">
                  <div style="padding:0;margin:10px 0;border:1px solid #ccc;">
                    <div class="tiny" style="padding:8px;color:#333;background: #fff;">
                      <div v-bind:class="bulletcolor(note.rank_score)" style="float:left;text-align:center;color:#fff;font-weight:bold;width:16px;height:16px;margin-right:8px;border-radius: 50%;"></div>
                      <strong>
                        <router-link :to="`/scorecards/${scorecard.scorecard_id}/categories/${note.category_id}`" style="text-decoration:none;color:#333;">
                          {{note.category_name}} 
                        </router-link> :
                        <router-link :to="`/scorecards/${scorecard.scorecard_id}/categories/${note.category_id}/measures/${note.measure_id}`" :title="note.measure_name" style="text-decoration:none;color:#333;">
                          {{ note.measure_name }}
                        </router-link>
                      </strong>
                    </div>
                    <div style="white-space: break-spaces;padding:5px 0 15px 32px;background: #fff;" v-if="note.snapshot_notes">
                      <span v-html="note.snapshot_notes" v-linkified />
                    </div>
                    <div style="padding:5px 0 15px 32px;background: #fff;" v-else>
                      <router-link :to="`/scorecards/${scorecard.scorecard_id}/categories/${note.category_id}/measures/${note.measure_id}`" :title="note.benchmark_description" style="text-decoration:none;">
                        {{ note.benchmark_description }}
                      </router-link>
                    </div>
                    <div class="tiny" style="padding:8px;background: #efefef;">
                      {{ note.created_at | moment("MMM DD, YYYY") }}
                      &bull;
                      <span v-if="note.user_name">{{ note.user_name + ' &bull; '}}</span>
                      <router-link :to="`/scorecards/${scorecard.scorecard_id}/categories/${note.category_id}/measures/${note.measure_id}`" :title="note.benchmark_description" style="text-decoration:none;">
                        Change Status...
                      </router-link>
                    </div>
                  </div>
                </div>
              
              </div>

            </div>

            <div v-if="(category_score < 1) && (measures.length > 1)">
              <p style="margin-top:20px;text-align:center">
                <br>Return to  
                <router-link :to="{ name: 'scorecard', params: { scorecard_id: $route.params.scorecard_id }}">{{ scorecard.scorecard_name }}</router-link>
              </p>
            </div>

          </div>
      </div>

      <div v-if="error" class="alert alert-danger">{{error}}</div>

      <!-- use the modal component, pass in the prop -->
      <AddTask v-if="showAddTask" @close="showAddTask = false" v-bind:scid="scid" v-bind:uid="user.user_id" v-bind:cid="category.category_id" @newTask='taskrender += 1' />

      <!-- use the modal component, pass in the prop -->
      <modal v-if="showModal" @close="showModal = false">
        <template v-slot:header>
          <h3>{{ category.category_name }}</h3>
        </template>
        <template v-slot:body>
          <p>
            {{ category.category_description }}
          </p>
        </template>
        <template v-slot:footer>
          <p v-if="user.user_id === account.user_id" class="tiny">To edit this description, close this window and then click the Pencil icon.</p>
          <p v-else class="tiny"></p>
        </template>
      </modal>

      <!-- use the modal component, pass in the prop -->
      <modal v-if="showEdit" @close="showEdit = false">
        <template v-slot:header>
          <h3>Edit Category</h3>
        </template>
        <template v-slot:body>
          <div class="form-grp">
            <label for="category_name">Category Name</label>
            <input type="text" v-model="category.category_name" name="category_name" maxlength="144" />
          </div>
          <div class="form-grp">
              <label for="category_description">Category Description</label>
              <textarea v-model="category.category_description" name="category_description" />
          </div>
          <div class="form-group">
              <button class="btn btn-primary" :disabled="loading" @click="editCategory">Save</button>
              |
              <a href="#" @click.prevent="showEdit = false" style="text-decoration:none;">Cancel</a>
          </div>
        </template>
        <template v-slot:footer>
          <div v-if="editmsg">{{editmsg}}</div>
          <div class="tiny" style="text-align: right;" v-else>{{ scorecard.scorecard_name }}</div>
        </template>
      </modal>

    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { accountService, snapshotService, userService } from '../_services';
import { scorecardService } from "../_services/scorecard.service"
import AddTask from "@/components/includes/AddTask"
import AccountTasks from "@/components/includes/AccountTasks"
import modal from "@/components/includes/InfoModal"
import searchbar from "@/components/includes/SearchBar"

export default {
  title: 'Category | Foclarity',
  name: "Category",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  data () {
      return {
        user: '',
        scorecard: '',
        category: '',
        category_owners: [],
        report: '',
        scid: this.$route.params.scorecard_id,
        cid: this.$route.params.category_id,
        measures: [],
        measure_samples: [
          {
            "measure_id": 1,
            "measure_name": "Ecosystem Strategy",
            "measure_description": "This is a sample tile description. It can be as long as you want it to be. It can include links to other websites, or even to other tiles inside this dashboard. It can also include links to other tiles inside this dashboard.",
            "snapshot": {
              "snapshot_id": 1,
              "snapshot_notes": "This is an example of a tile that is on hold. There's no activity on it, and it's not moving forward.",
              "rank_score": 1,
              "benchmark_description": "On Hold",
              "created_at": "2020-01-01 00:00:00",
              "user_name": "John Doe"
            },
          },
          {
            "measure_id": 2,
            "measure_name": "SWOT Analysis",
            "measure_description": "This is a sample tile description. It can be as long as you want it to be. It can include links to other websites, or even to other tiles inside this dashboard. It can also include links to other tiles inside this dashboard.", 
            "snapshot": {
              "snapshot_id": 2,
              "snapshot_notes": "This is an example of a tile that is behind. It's not moving forward as fast as you'd like it to, but there's some activity on it.",
              "rank_score": 2,
              "benchmark_description": "Behind",
              "created_at": "2020-01-01 00:00:00",
              "user_name": "June Nguyen"
            },
          },
          {
            "measure_id": 3,
            "measure_name": "Sources of Innovation",
            "measure_description": "This is a sample tile description. It can be as long as you want it to be. It can include links to other websites, or even to other tiles inside this dashboard. It can also include links to other tiles inside this dashboard.",
            "snapshot": {
              "snapshot_id": 3,
              "snapshot_notes": "This is a tile where you're making progress. It's moving forward, but there's still work to be done.",
              "rank_score": 3,
              "benchmark_description": "On Track",
              "created_at": "2020-01-01 00:00:00",
              "user_name": "Curtis Johnson"
            },
          },
          {
            "measure_id": 4,
            "measure_name": "Diversity, Equity & Inclusion",
            "measure_description": "This is a sample tile description. It can be as long as you want it to be. It can include links to other websites, or even to other tiles inside this dashboard. It can also include links to other tiles inside this dashboard.", 
            "snapshot": {
              "snapshot_id": 4,
              "snapshot_notes": "This is a tile that's almost done. It's moving forward nicely, and there's not much left to do until it's complete.",
              "rank_score": 4,
              "benchmark_description": "Almost Done",
              "created_at": "2020-01-01 00:00:00",
              "user_name": "Sally Smith"
            },
          },
          {
            "measure_id": 5,
            "measure_name": "Vision, Mission & Values",
            "measure_description": "This is a sample tile description. It can be as long as you want it to be. It can include links to other websites, or even to other tiles inside this dashboard. It can also include links to other tiles inside this dashboard.",
            "snapshot": {
              "snapshot_id": 5,
              "snapshot_notes": "This is a tile where the work is complete. It's done, and there's nothing left to do.",
              "rank_score": 5,
              "benchmark_description": "Completed",
              "created_at": "2020-01-01 00:00:00",
              "user_name": "Mike Anderson"
            },
          },
        ],
        first_measure_id: -1,
        categories: [],
        next_id: -1,
        prev_id: -1,
        next: true,
        prev: true,
        measurescores: [],
        category_score: 0,
        snapshots: [],
        notescore: 0,
        message: '',
        emsg: '',
        submitted: false,
        loading: true,
        toggle: true,
        showModal: false,
        showEdit: false,
        showAddTask: false,
        taskrender: 0,
        returnUrl: '',
        error: '',
        editmsg: '',
        hide: false,
        series: [],
        chartOptions: {
          chart: {
            height: 400,
            type: 'radar',
            toolbar: {
              show: true
            }
          },
          xaxis: {
            categories: ["one","two","three","four","five"]
          },
          theme: {
            palette: 'palette1'
          }
        },
        catkey: 0,
        // chart options for line charts
        chartOptionsLine: {
          chart: {
            height: 200,
            type: 'area',
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          markers: {
            size: 5
          },
          theme: {
            palette: 'palette1'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: [
              this.chartdate(2), 
              this.chartdate(1), 
              this.chartdate(0)
            ],
          },
          yaxis: {
            max: 5,
            forceNiceScale: true
          }
        },
      }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    editCategory: function () {
        //console.log("editing")
        scorecardService.updateCategory(this.scorecard.scorecard_id, this.category.category_id, this.category.category_name, this.category.category_description)
            .then(
                data => {
                    this.editmsg = data.category_name + ' Saved. Close this window to continue.';
                    this.showEdit = false ;
                },
                error => {
                    this.editmsg = error + ' Please close and try again.'
                }
            );
    },
    bulletcolor: function (score) {
      if (score === 1) {
        return {'emerging': true}
      } else if (score === 2) {
        return {'below-average': true}
      } else if (score === 3) {
        return {'average': true}
      } else if (score === 4) {
        return {'above-average': true}
      } else if (score === 5) {
        return {'leadership': true}
      } else {
        return 'emerging'
      }
    },
    activeAccount() {
      this.account = accountService.getActiveAccount() ;
      this.user = userService.getActiveUser() ;
    },
    contactSupport: function () {
      const subj = "Free consultation request on my " + this.measure.measure_name
      const msg = "I need help with my " + this.measure.measure_name
      this.submitted = true ;

      accountService.contactSupport(subj, msg).then(
        data => {
          if (data.status == 'failed') {
            this.submitted = false ;
            this.error = 'failed to send message' ;
          } else {
            this.error = ""
            this.message = ""
            this.submitted = false ;
            this.emsg = "Message sent!"
          }
      },
      error => {
        this.error = error
        this.loading = false
      });
    },
    chartdate: (past) => {
      var d = new Date();
      d.setMonth(d.getMonth() - past);
      var month = new Array();
      month[0] = "Jan";
      month[1] = "Feb";
      month[2] = "Mar";
      month[3] = "Apr";
      month[4] = "May";
      month[5] = "Jun";
      month[6] = "Jul";
      month[7] = "Aug";
      month[8] = "Sep";
      month[9] = "Oct";
      month[10] = "Nov";
      month[11] = "Dec";
      var n = month[d.getMonth()];
      return n ;
    },
    switchMeasure: function (measure_id) {
      this.$router.push("scorecards/"+this.scid+"/categories/"+this.cid+"/measures/"+measure_id)
    },
    getlinechartseries: function(c) {
      var obj = this.measurescores.find(obj => obj.measure_name == c);
      var score = 0
      try {
        score = obj.score
      } catch {
        score = 0
      }
      const series = [{
                name: "Score",
                data: [0, 0, score]
            }]
      return series
    },
    measurescore: function (c) {
      var obj = this.measurescores.find(obj => obj.measure_name == c);
      var score = 0
      try {
        score = obj.score
      } catch {
        score = 0
      }
      if (score < 1.5) {
        return {'emerging': true}
      } else if (score >= 1.5 && score < 2.5) {
        return {'below-average': true}
      } else if (score >= 2.5 && score < 3.5) {
        return {'average': true}
      } else if (score >= 3.5 && score < 4.5) {
        return {'above-average': true}
      } else if (score >= 4.5) {
        return {'leadership': true}
      } else {
        return 'emerging'
      }

    },
    activeScorecard: function () {
      this.scid = this.$route.params.scorecard_id
      scorecardService.readScorecard(this.scid).then(
        data => {
          this.scorecard = data.scorecard
        },
        error => {
          this.error = error
          this.loading = false
        }
      )
    },
    nextCategory() {
      window.location.href= `/scorecards/${this.scid}/categories/${this.next_id}`
    },
    prevCategory() {
      window.location.href= `/scorecards/${this.scid}/categories/${this.prev_id}`
    },
    changeCategory: function(event) {
      window.location.href= `/scorecards/${this.scid}/categories/${event.target.value}`
    },
    getCategory: function () {
      scorecardService.switchCategory(this.scid, this.cid).
      then(data => {
        this.category = data.category
        window.document.title = this.category.category_name + " | Category"
        this.measures = data.measures
        this.categories = data.categories
        this.category_score = data.category_score
        this.measures.reverse() ;

        // get measure owners
        var tempowners = []
        this.measures.forEach( i => {
          if (i.measure_owners) {
            i.measure_owners.forEach( m => {
              if (!tempowners.includes(m.owner_email)) {
                tempowners.push(m.owner_email)
                this.category_owners.push(m);
              }
            })
          }
        })        

        try {
          this.first_measure_id = this.measures[0].measure_id;
        } catch {
          this.first_measure_id = -1
        }

        this.categories.forEach((cat, index) => {
          if (cat.category_id == this.cid) {
            if ((index - 1) >= 0) {
              this.next_id = this.categories[index - 1].category_id ;
            }
            if ((index + 1) < this.categories.length) {
              this.prev_id = this.categories[index + 1].category_id ;
            }
          }
        })

        if (this.prev_id < 0) {
          this.prev = false ;
        }

        if (this.next_id < 0) {
          this.next = false ;
        }            

      }).
      then(() => {
        snapshotService.readCategorySnapshots(this.scid, this.cid).
        then(data => {
          this.snapshots = data.snapshots
          this.category_score = data.category_score
          // see if you can create a list of categories for the chart
          const newcats = [...new Set(this.measures.map(x => x.measure_name))]
          var newcatstrimmed = [...new Set(this.measures.map(x => (x.measure_name.substring(0,12) + "...")))]
          
          this.chartOptions = {
            chart: {
              height: 350,
              type: 'radar',
              toolbar: {
                show: false
              }
            },
            xaxis: {
              categories: newcatstrimmed
          },
          theme: {
            palette: 'palette1'
          }
        }

        this.catkey ++
          // this.chartOptions.xaxis.categories = newcats;
          // this.catkey += 1 ;
          //console.log(newcats)

          // now see if you can total up the category scores and divide by the array length
          // for each category, sum rank_scores and divide by number of snapshots
          // then push that into the newscores array
          var newscores = [] ;
          var catscores = [] ;
          var cat
          for (cat of newcats) {
            //console.log(cat)
            var ss
            var score = 0
            for (ss of data.snapshots) {
              if (ss.measure_name == cat) {
                score = score + ss.rank_score
                //console.log(score)
              }
            }
            //console.log(score)
            newscores.push(score)
            catscores.push({"measure_name":cat, "score": score})
          }

          this.measurescores = catscores ;
          
          // add in bestscores for the graph size
          var bestscores = []
          var bestscore = 0
          var i ;
            for (i = 0; i < newscores.length; i++) {
              bestscores.push(5)
              bestscore = bestscore + 5
            }
          var bestave = bestscore / bestscores.length
          const latest = {"name": this.category.category_name+" score: "+this.category_score, "data": newscores}
          const best = {"name": "Best possible: "+bestave, "data": bestscores}
          var newseries = []
          newseries.push(latest)
          newseries.push(best)

          this.series = newseries
          this.loading = false
          this.catkey += 1 ;
          }
        )
      }).
      catch(error => {
          this.error = error
          this.loading = false
      })
    },
  },
  components: {
    AccountTasks,
    AddTask,
    modal,
    searchbar
  },
  created () {
    this.getCategory();
    this.activeAccount() ;
    this.activeScorecard() ;
  },
  watch: {
    newTask: function () {
      this.taskrender += 1;
    },
    $route(to) {
      this.cid = to.params.category_id
      this.scid = to.params.scorecard_id
      this.getCategory();
      this.activeAccount() ;
      this.activeScorecard() ;
    }
  },
  computed: {
    filteredSnapshots() {
      if (this.notescore === 0) {
        var newsnaps = this.snapshots
        return newsnaps.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).reverse() ;
      } else {
        return this.snapshots.filter((note) => {return note.rank_score === this.notescore}, false);
      }
    },
    cardcolor: function () {
      var score = this.category_score 
      if (score < 1.5) {
        return {'emerging': true}
      } else if (score >= 1.5 && score < 2.5) {
        return {'below-average': true}
      } else if (score >= 2.5 && score < 3.5) {
        return {'average': true}
      } else if (score >= 3.5 && score < 4.5) {
        return {'above-average': true}
      } else if (score >= 4.5) {
        return {'leadership': true}
      } else {
        return 'emerging'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.activefilter {
  color: #333;
  text-decoration: none;
}

</style>

